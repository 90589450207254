import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "../UserContext";
import { ConcessionsContext } from '../service/ConcessionsContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

const locAddMsg = "Location added.";
const locAddFail = "Failed to add location to database.";
const locDeleteMsg = "Location removed.";
const locDeleteFail = "Failed to remove location.";
const locAssignedFail = "Failed to remove location. This location is assigned to a unit.";

export const Locations = () => {
	const [locations, setLocations] = useState([]);
	const [locationToDelete, setLocationToDelete] = useState();
	const [locationToDeleteId, setLocationToDeleteId] = useState();
	const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
	const [sections, setSections] = useState([])
	const [displayModal, setDisplayModal] = useState(false);
	const [newLocation, setNewLocation] = useState({locationName: '', section: ''});
	const [newLocationClass, setNewLocationClass] = useState({locationNameError : false , sectionError: false});
	const user = useContext(UserContext);
	const global = useContext(ConcessionsContext);

	useEffect(() => {
		user.apiCall('locations').then(res => {
			setLocations(res.data);
		})
		user.apiCall('sections').then(res => {
			setSections(res.data);
		})
	}, []);

	const addLocation = () => {
		const body = {
			LocationName: newLocation.locationName.trim(),
			SectionId: newLocation.section
		}
		const sectionLocationError = {
			locationNameError : false,
			sectionError : false
		}
		if(newLocation.locationName.trim().length == "0"){

			sectionLocationError.locationNameError = true

		}
		if(newLocation.section == ""){
			sectionLocationError.sectionError = true
		}

		setNewLocationClass(sectionLocationError)
		if(sectionLocationError.locationNameError || sectionLocationError.sectionError ){
			return;
		}
		user.apiCall('locations', body, 'POST')
			.then((res) => {
				if (res.status === 200) {
					global.successToast(locAddMsg);
					user.apiCall('locations').then(res => {
						setLocations(res.data)
					})
					setNewLocation({ locationName: '', section: '' })
					setDisplayModal(false)
				}
				else {
					global.errorToast(locAddFail);
				}
			}).catch((res) => {
				global.errorToast("Failed. Invalid Data or Location Exists")
			})
	}

	const updateLocation = (props) => {
		const body = {
			LocationId: props.newData.locationId,
			LocationName: props.newData.locationName.trim(),
			SectionId: props.newData.sectionId
		}
		user.apiCall('locations/' + props.newData.locationId, body, 'PUT')
			.then((res) => {
				if (res.status === 200) {
					global.successToast(locAddMsg);
					user.apiCall('locations').then(res => {
						setLocations(res.data)
					})
				}
				else {
					global.errorToast(locAddFail);
				}
			}).catch((err)=>{
				global.errorToast("Failed to update")
			})
	}

	const deleteLocation = () => {
		user.apiCall('locations/' + locationToDeleteId, {}, 'DELETE')
			.then((res) => {
				if (res.status === 200) {
					global.successToast(locDeleteMsg);
					user.apiCall('locations').then(res => {
						setLocations(res.data)
					})
				}
				else {
					global.errorToast(locDeleteFail);
				}
			})
			.catch((res) => {
				if (res.response.status === 400) {
					global.errorToast(locAssignedFail);
				}
				else {
					global.errorToast(locDeleteFail);
				}
			})
	}

	const addRow = () => {
		let temp = [...locations]
		temp.push({ locationName: null,sectionId: null,sectionName: null })
		setLocations(temp)
	}

	const inputTextEditor = (props, field) => (
		<InputText type="text"
			value={props.rowData[field]}
			onChange={(e) => props.editorCallback(e.target.value)}
		/>
	)

	const dropdownEditor = (props, field) => (
		<Dropdown value={props.rowData[field]}
			options={sections}
			optionLabel="sectionName"
			optionValue="sectionId"
			onChange={(e) => props.editorCallback(e.value)}
			style={{ width: '100%' }}
			placeholder="Select a Section"
			itemTemplate={(option) => {
				return <span >{option.sectionName}</span>
			}}
		/>
	);

	const sectionBodyTemplate = (rowData) => {
		return rowData.sectionName;
	}

	const buttonBodyTemplate = (rowData) => {
		if (rowData.locationId) {
			return (
				<Button tooltip="Delete Location"
					tooltipOptions={{ position: 'left' }}
					onClick={() => {
						setLocationToDeleteId(rowData.locationId);
						setLocationToDelete(rowData.locationName);
						setDisplayDeleteDialog(true);
					}}
					label={<i className="pi pi pi-times"></i>}
					className="p-button-rounded p-button-primary"
				/>
			)
		}
	}

	const buttonHeaderTemplate = () => (
		<Button tooltip="Add Location"
			tooltipOptions={{ position: 'left' }}
			style={{ backgroundColor: "white", color: "#477fdf" }}
			onClick={() => setDisplayModal(true)}
			label={<i className="pi pi-plus"></i>}
			className="p-button-rounded p-button-primary"
		/>
	)

	return (
		<div className="p-grid">
			<Dialog style={{ minWidth: '25vw' }}
				header="Add New Location"
				visible={displayModal}
				onHide={() => setDisplayModal(false)}
				footer={
					<Button label="Save"
						icon="pi pi-check"
						onClick={() => addLocation()}
						autoFocus
					/>
				}
			>
				<form>
					<p>
						<label htmlFor="locationNameModal">Location Name:</label>
						<InputText id="locationNameModal"
							value={newLocation.locationName}
							onChange={(e) => {setNewLocation({ locationName: e.target.value, section: newLocation.section }); if(!(e.value == ""))setNewLocationClass({...newLocationClass, locationNameError : false})}}
							style = {{borderColor : newLocationClass.locationNameError?'red':'#ced4da'}}/>
						{newLocationClass.locationNameError && <div style={{color:'red'}}>Location name cannot be empty</div>}
						
					</p>
					<p>
						<label htmlFor="sectionDropdownModal">Section:</label>
						<Dropdown id="sectionDropdownModal"
							value={newLocation.section}
							options={sections}
							optionLabel="sectionName"
							optionValue="sectionId"
							onChange={(e) => {setNewLocation({ locationName: newLocation.locationName, section: e.value });if(!(e.value == ""))setNewLocationClass({...newLocationClass, sectionError : false})}}
							placeholder="Select a Section"
							itemTemplate={(option) => {
								return <span >{option.sectionName}</span>
							}}
						/>
						{newLocationClass.sectionError && <div style={{color:'red'}}>Please select a section</div>}
					</p>
				</form>
			</Dialog>
			<ConfirmDialog visible={displayDeleteDialog}
				onHide={() => setDisplayDeleteDialog(false)}
				message={"Do you want to delete " + locationToDelete + "?"}
				header="Delete Confirmation"
				icon="pi pi pi-info-circle"
				accept={() => deleteLocation()}
				reject={null}
			/>
			<div className="p-col-12">
				<div className="card">
					<h5>Locations</h5>
					<div>
						<DataTable value={locations}
							sortField="locationName"
							sortOrder={1}
							filterDisplay="row"
							editMode="row"
							onRowEditComplete={(props) => updateLocation(props)}
							responsiveLayout="scroll"
						>
							<Column header="Location Name"
								field="locationName"
								align='center'
								alignHeader='center'
								editor={(props) => inputTextEditor(props, 'locationName')}
								filterPlaceholder="Search by name"
								filterMatchMode="contains"
								showFilterMenu={false}
								filter
								sortable
							/>
							<Column align='center'
								alignHeader='center'
								field="sectionId"
								filter
								filterPlaceholder="Search by name"
								filterMatchMode="contains"
								showFilterMenu={false}
								filterField="sectionName"
								body={sectionBodyTemplate}
								editor={(props) => dropdownEditor(props, 'sectionId')}
								sortable
								header="Section Name"
							/>
							<Column align='center'
								alignHeader='center'
								rowEditor
								headerStyle={{ width: '10%', minWidth: '8rem' }}
								bodyStyle={{ textAlign: 'center' }}
							/>
							<Column align='center'
								alignHeader='center'
								style={{ width: '10%' }}
								field="buttons"
								body={buttonBodyTemplate}
								header={buttonHeaderTemplate}
							/>
						</DataTable>
					</div>
				</div>
			</div>
		</div>
	);
}
